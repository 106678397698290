import { KeyValue } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { Masks } from 'src/app/shared/constants/masks';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { CampaignComboboxResponseDTO } from 'src/app/shared/domains/campaign';
import { ClientDTO } from 'src/app/shared/domains/clientDto';
import { User } from 'src/app/shared/domains/user';
import { ClientService } from 'src/app/shared/services/client.service';
import { DataImportService } from 'src/app/shared/services/data-import.service';
import { GamificationService } from 'src/app/shared/services/gamification-config.service';
import { NoticeService } from 'src/app/shared/services/notice.service';
import { UserClientService } from 'src/app/shared/services/user-client.service';
import { AlertUserTypesWithLabels } from 'src/app/shared/types/alert-user.type';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';

@Component({
  selector: 'app-register-notice',
  templateUrl: './register-notice.component.html',
  styleUrls: ['./register-notice.component.scss'],
})
export class RegisterNoticeComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;
  noticeId: number | null = null;
  image!: File | undefined;
  clients: ClientDTO[] = [];
  clientUserId: number | null = null;
  clientName = '';
  userTypes = AlertUserTypesWithLabels;
  mask = Masks;

  form: FormGroup = this.formBuilder.group({
    id: [''],
    url: [''],
    name: ['', Validators.required],
    description: ['', Validators.required],
    imgUri: [''],
    recurrence: ['', Validators.required],
    alertType: [[], Validators.required],
    startTimestamp: ['', Validators.required],
    endTimestamp: ['', Validators.required],
    groups: [[]],
    campaignId: [null],
    campaignName: [null]
  });

  gamificationFields: KeyValue<string, string>[] = [
    {
      key: 'GamificationRead',
      value: 'Visualizar'
    },
  ] as KeyValue<string, string>[];

  startDateFormInvalid = false;
  endDateFormInvalid = false;

  selectedImage: any = null;
  isDisabledGrid = false;
  user: User = new User();
  canCreate: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  disableAlertType = true;
  characterCountDescription: number = 0;
  characterCountName: number = 0;
  groupList: number[] = [];
  onlyGroups: boolean = false

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private noticeService: NoticeService,
    private toastComponent: ToastComponent,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dataImportService: DataImportService,
    private userClientService: UserClientService,
    private clientService: ClientService,
    private permissionsService: NgxPermissionsService,
    public globalFunctions: GlobalFunctions,
    public gamificationService: GamificationService
  ) {
    this.gamificationService.registerGamificationFields(this.gamificationFields, this.form)
    this.form.get('groups')?.valueChanges.subscribe(value => {
      if (this.onlyGroups) {
        this.form.get('groups')?.addValidators(Validators.required);
        return;
      }
      this.form.get('groups')?.removeValidators(Validators.required);
    })

    this.form.get('campaignId')?.valueChanges.subscribe((value) => {
      if (value) {
        this.gamificationFields.forEach((field) => {
          this.form.get(this.gamificationService.getAllowFieldName(field.key))?.disable();
          this.form.get(this.gamificationService.getAllowFieldName(field.key))?.setValue(false);
          this.form.get(this.gamificationService.getValueFieldName(field.key))?.disable();
          this.form.get(this.gamificationService.getValueFieldName(field.key))?.setValue(null);
        })
        return
      }
      this.gamificationFields.forEach((field) => {
        this.form.get(this.gamificationService.getValueFieldName(field.key))?.setValue(null);
        this.form.get(this.gamificationService.getAllowFieldName(field.key))?.setValue(true);
        this.form.get(this.gamificationService.getAllowFieldName(field.key))?.enable();
      })
      this.form.get('isGeral')?.setValue(true);
    })
  }

  ngAfterViewInit(): void {
    if (this.clientUserId) {
      this.setFormClientNotice();
      this.setDisabledAlertType();
    }
  }

  async getPermissions() {
    this.canCreate = await this.permissionsService.hasPermission(
      this.clientUserId ? ProfilePermissions.CREATE_CLIENT_NOTICES : ProfilePermissions.CREATE_NOTICES
    );
    this.canEdit = await this.permissionsService.hasPermission(
      this.clientUserId ? ProfilePermissions.UPDATE_CLIENT_NOTICES : ProfilePermissions.UPDATE_NOTICES
    );
    this.canDelete = await this.permissionsService.hasPermission(
      this.clientUserId ? ProfilePermissions.DELETE_CLIENT_NOTICES : ProfilePermissions.DELETE_NOTICES
    );
  }

  async ngOnInit() {
    this.getId();
    this.route.queryParams.subscribe((params) => {
      if (params['clientId']) {
        this.clientUserId = params['clientId'];
        this.setFormClientNotice();
      }
      if (params['id']) {
        const id = params['id'];
        this.getEditData(id);
      }
    });
    await this.getPermissions();
    this.getClient();
  }

  setDisabledAlertType() {
    if (this.clientUserId) {
      this.disableAlertType = true;
    } else {
      this.disableAlertType = false;
    }
  }

  setFormClientNotice() {
    this.form.get('alertType')?.disable();
    this.form.get('alertType')?.setValue(['BENEFICIARY']);
    this.cdr.detectChanges();
  }

  async getClient() {
    if (this.clientUserId) {
      this.clientService
        .get(this.clientUserId)
        .subscribe((data) => {
          this.clientName = data.corporateName;
        });
    }
  }

  handleChangeStartDate() {
    this.startDateFormInvalid = false;
  }

  async getClientData() {
    const result = await this.userClientService.getResumedUserClientByUserIdAndClientId(
      this.user?.id ?? 0
    );
    this.clientUserId = result.id;
    this.setFormClientNotice();
  }

  handleChangeEndDate() {
    this.endDateFormInvalid = false;
  }

  updateCharacterCountName(value: string): void {
    this.characterCountName = value.length;
  }

  updateCharacterCountDescription(value: string): void {
    this.characterCountDescription = value.length;
  }

  onImgAdded(event: any): void {
    const file = event.target.files[0];
    if (file) this.handleFile(file);
  }

  handleDisabledGrid() {
    const alertType = this.form.get('alertType')?.value;

    this.isDisabledGrid =
      Array.isArray(alertType) &&
      alertType.some((type) => ['INTERNAL_USER', 'PCV'].includes(type));
  }

  onDrop(event: any): void {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.handleFile(files[0]);
    }
  }

  handleFile(file: File): void {
    const validTypes = ['image/png', 'image/jpeg'];
    const maxSize = 15 * 1024 * 1024;
    if (!validTypes.includes(file.type)) {
      this.toastComponent.showWarningCustomMessage(
        'Formato Inválido!',
        'Por favor, selecione um arquivo PNG ou JPG.'
      );
      return;
    }

    if (file.size > maxSize) {
      this.toastComponent.showWarningCustomMessage(
        'Tamaho inválido',
        'Por favor, selecione um arquivo com tamanho máximo de 15mb.'
      );
      return;
    }

    this.removeImg(file);
    this.image = file;
    const reader = new FileReader();
    reader.onload = (e: Event) => {
      const target = e.target as FileReader;
      this.selectedImage = target.result as string;
    };
    reader.onerror = (error) => {
      console.error('Erro ao ler o arquivo:', error);
      this.toastComponent.showWarningCustomMessage(
        'Erro ao ler o arquivo!',
        'Por favor, tente novamente.'
      );
    };
    reader.readAsDataURL(file);
  }

  downloadImage(imageUrl: string, imageName: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = imageName;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  getFileName() {
    return `IMG_${this.form.get('name')?.value}`
      .replace(' ', '_')
      .toLocaleUpperCase();
  }

  removeImg(fileInput: any) {
    if (this.form.get('id')?.value && this.form.get('imgUri')?.value) {
      this.form.get('hasBeenImageDeleted')?.setValue(true);
    }

    this.selectedImage = null;
    this.image = undefined;
    fileInput.value = '';
  }

  async getImage(imgUrl?: string) {
    this.form.get('imgUri')?.setValue(imgUrl);

    if (imgUrl) {
      const blob = await this.dataImportService.getImage(imgUrl);
      const file = new File([blob], 'img.' + this.getImageExtension(blob), { type: blob?.type });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
      this.image = file;
    }
  }

  getImageExtension(blob: Blob): string {
    const matches = blob?.type?.match(/[^/]*$/);
    if (matches && matches?.length > 0) {
      return matches[0];
    }
    return '';
  }

  async getEditData(id: number) {
    const noticeData = this.clientUserId
      ? await this.noticeService.getClientNotice(id)
      : await this.noticeService.getNotice(id);

    const types = this.clientUserId
      ? [noticeData.type]
      : noticeData.targetAudience?.map(
        (audience) => audience.noticeType
      )
    if (noticeData) {
      this.form.patchValue({
        id: noticeData.id,
        name: noticeData.name,
        description: noticeData.description,
        recurrence: noticeData.recurrence,
        alertType: types,
        startTimestamp: noticeData.startTimestamp,
        endTimestamp: noticeData.endTimestamp,
        url: noticeData.url,
        groups: noticeData.groups,
        campaignId: noticeData.campaignId,
        campaignName: noticeData.campaignName
      });
      this.gamificationService.setGamificationValues(this.gamificationFields, this.form, noticeData)

      this.updateCharacterCountDescription(noticeData.description);
      this.updateCharacterCountName(noticeData.name);

      if (noticeData.imageUrl) {
        await this.getImage(noticeData.imageUrl);
      }

      if (noticeData.clients) {
        this.clients = noticeData.clients;
      }

      this.cdr.detectChanges();
      this.handleDisabledGrid();
    }

    if (!this.canEdit) this.form.disable();
  }

  handleSelectedListChange(selectedList: ClientDTO[]) {
    this.clients = selectedList
  }

  markFormFieldsAsTouched() {
    Object.values(this.form.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        Object.values(control.controls).forEach((innerControl) => {
          innerControl.markAsTouched();
        });
      } else {
        control.markAsTouched();
      }
    });
  }

  async onSubmit() {
    const isEdit: boolean = this.form.valid && this.noticeId !== undefined;
    const isRegister: boolean = this.form.valid && this.noticeId === undefined;

    if (!this.validateDate()) return

    if (this.form.get('groups')?.invalid) {
      this.toastComponent.showWarningCustomMessage(
        'Selecione pelo menos um grupo!'
      );
      return;
    }


    if (this.form.valid) {
      try {
        const formData = this.form.value;
        const gamificationObject = this.gamificationService.getGamificationObject(this.gamificationFields, this.form);
        let noticeData;
        switch (true) {
          case isRegister && this.clientUserId !== null:
            noticeData = {
              id: formData.id,
              name: formData.name,
              description: formData.description,
              type: this.form.get('alertType')?.value[0],
              recurrence: formData.recurrence,
              startTimestamp: formData.startTimestamp,
              endTimestamp: formData.endTimestamp,
              clientId: this.clientUserId,
              groups: formData.groups,
              url: this.removeInvisibleChars(formData.url),
              ...gamificationObject,
              campaignId: formData.campaignId
            };
            break;

          case isRegister && !this.clientUserId:
            noticeData = {
              id: formData.id,
              name: formData.name,
              description: formData.description,
              targetAudience: formData.alertType.map((type: any) => ({
                noticeType: type,
              })),
              recurrence: formData.recurrence,
              startTimestamp: formData.startTimestamp,
              endTimestamp: formData.endTimestamp,
              clients: this.clients,
              url: this.removeInvisibleChars(formData.url),
              ...gamificationObject,
              campaignId: formData.campaignId
            };
            break;

          case isEdit && this.clientUserId !== null:
            noticeData = {
              id: formData.id,
              name: formData.name,
              description: formData.description,
              type: this.form.get('alertType')?.value[0],
              recurrence: formData.recurrence,
              startTimestamp: formData.startTimestamp,
              endTimestamp: formData.endTimestamp,
              clientId: this.clientUserId,
              groups: formData.groups,
              url: this.removeInvisibleChars(formData.url),
              ...gamificationObject,
              campaignId: formData.campaignId
            };
            break;

          case isEdit && !this.clientUserId:
            noticeData = {
              id: formData.id,
              name: formData.name,
              description: formData.description,
              targetAudience: formData.alertType.map((type: any) => ({
                noticeType: type,
              })),
              recurrence: formData.recurrence,
              startTimestamp: formData.startTimestamp,
              endTimestamp: formData.endTimestamp,
              clients: this.clients,
              url: this.removeInvisibleChars(formData.url),
              ...gamificationObject,
              campaignId: formData.campaignId
            };
            break;

          default:
        }
        if (noticeData) {
          await this.handleNoticeOperation(noticeData);
        }
      } catch (err) {
        console.error(err);
      }
    } else if (!this.form.valid) {
      this.toastComponent.showWarningCustomMessage(
        'Campo(s) obrigatório(s) não preenchido(s)!'
      );

      this.startDateFormInvalid = this.startDateFormInvalid || this.form.get('startTimestamp')?.value == '';
      this.endDateFormInvalid = this.endDateFormInvalid || this.form.get('endTimestamp')?.value == '';
    }
  }

  validateDate(): boolean {
    const endDate = new Date(this.form.get('endTimestamp')?.value);
    const startDate = new Date(this.form.get('startTimestamp')?.value);
    const currentDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      new Date().getHours(),
      new Date().getMinutes(),
      0,
      0
    );
    const minDate = new Date(currentDate.getTime() + 5 * 60000);

    if (startDate && startDate < minDate) {
      this.toastComponent.showWarningCustomMessage(
        'Data e hora iniciais devem ser pelo menos 5 minutos maiores que a data e hora atuais.'
      );
      this.startDateFormInvalid = true;
      return false;
    }

    if (endDate && endDate < startDate || endDate < currentDate) {
      this.toastComponent.showWarningCustomMessage(
        'Data e hora finais devem ser maiores à data e hora iniciais.'
      );
      this.endDateFormInvalid = true;
      return false;
    }

    return true;
  }

  confirmDeleteNotice() {
    this.confirmModal
      ?.showModal(
        'Deseja mesmo excluir este aviso?',
        'Caso confirme, essa ação não poderá ser desfeita.'
      )
      .subscribe((isAccepted) => {
        if (isAccepted) {
          this.removeNotice();
          this.toastComponent.showSuccessCustomMessage(
            'Sucesso!',
            'Aviso excluído com sucesso!',
            3000
          );
        }
      });
  }

  async removeNotice() {
    try {
      if (this.noticeId) {
        if (this.clientUserId) {
          await this.noticeService.deleteClientNotice(this.noticeId);
        } else {
          await this.noticeService.delete(this.noticeId);
        }
      }
      if (this.clientUserId) {
        this.redirectTo('/notices-client');
      } else {
        this.redirectTo('/notices');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async handleNoticeOperation(noticeData: any) {
    try {
      let promise;

      if (this.noticeId === undefined) {
        promise = this.clientUserId
          ? this.noticeService.registerClientNotice(noticeData, this.image).then(() => {
            this.toastComponent.showSuccessCustomMessage(
              'Sucesso!',
              'Aviso criado com sucesso!',
              3000
            );
          })
          : this.noticeService.registerNotice(noticeData, this.image).then(() => {
            this.toastComponent.showSuccessCustomMessage(
              'Sucesso!',
              'Aviso criado com sucesso!',
              3000
            );
          });
      } else {
        promise = this.clientUserId
          ? this.noticeService.editClientNotice(noticeData, this.image).then(() => {
            this.toastComponent.showSuccessCustomMessage(
              'Sucesso!',
              'Aviso editado com sucesso!',
              3000
            );
          })
          : this.noticeService.edit(noticeData, this.image).then(() => {
            this.toastComponent.showSuccessCustomMessage(
              'Sucesso!',
              'Aviso editado com sucesso!',
              3000
            );
          });
      }

      await promise;
      if (this.clientUserId) {
        this.redirectTo('/notices-client');
      } else {
        this.redirectTo('/notices');
      }
    } catch (err) {
      console.error(err);
    }
  }
  /* else {
   */
  twoDigits(value: number): string {
    // Adiciona um zero à esquerda se o valor for menor que 10
    return value < 10 ? `0${value}` : `${value}`;
  }
  maxDate(): string {
    // Defina o ano máximo como 9999 para limitar a quatro dígitos
    const maxYear = 9999;

    // Obtém a data atual
    const currentDate = new Date();

    // Define o ano máximo no formato ISO para o atributo 'max'
    const maxDateString = `${maxYear}-${this.twoDigits(
      currentDate.getMonth() + 1
    )}-${this.twoDigits(currentDate.getDate())}T${this.twoDigits(
      currentDate.getHours()
    )}:${this.twoDigits(currentDate.getMinutes())}`;

    return maxDateString;
  }

  minDate(): string {
    // Obtém a data e hora atual no formato adequado para datetime-local
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  minEndDate(): string {
    const startTimestampValue = this.form.get('startTimestamp')?.value;

    // Se não houver uma data inicial, retorna uma string vazia
    if (!startTimestampValue) {
      return '';
    }

    const startDate = new Date(startTimestampValue);

    const year = startDate.getFullYear();
    const month = ('0' + (startDate.getMonth() + 1)).slice(-2);
    const day = ('0' + startDate.getDate()).slice(-2);
    const hours = ('0' + startDate.getHours()).slice(-2);
    const minutes = ('0' + startDate.getMinutes()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  onDragOver(event: any): void {
    event.preventDefault();
  }

  async getId() {
    this.route.queryParams.subscribe(async (params) => {
      const noticeId = params['id'];
      this.noticeId = noticeId;
    });
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    if (this.clientUserId) {
      extras = {
        queryParams: { clientId: this.clientUserId },
      };
    }
    this.router.navigate([path], extras);
  }

  get hasPermissions() {
    if (!this.form.get('id') && this.canCreate) return true;
    if (this.form.get('id') && this.canEdit) return true;

    return false;
  }

  removeNoticeUserType(index: number): void {
    const alertTypesCtrl = this.form.get('alertType');
    const alertTypes = alertTypesCtrl?.value;
    alertTypes.splice(index, 1);
    alertTypesCtrl?.patchValue(alertTypes);
    this.handleDisabledGrid();
  }

  getNoticeLabelFromValue(value: any): string | undefined {
    return AlertUserTypesWithLabels?.find(
      (p) => p.value.toLocaleLowerCase() === value.toLocaleLowerCase()
    )?.label;
  }

  onEemitterGroupSelectionChanged(event: { groups: number[], onlyGroups: boolean }) {
    this.onlyGroups = event.onlyGroups;
    this.form.get('groups')?.setValue(null);
    this.form.get('groups')?.setValue([...event.groups]);
  }

  onSelectCampaign(event: CampaignComboboxResponseDTO | null) {
    if (!event) {
      this.toastComponent.showWarningCustomMessage('Ao desvincular a campanha os pontos gerados por ela serão removidos.');
    }
    this.form.get('campaignId')?.setValue(event?.id ?? null);
  }

  removeInvisibleChars(value: string): string {
    return this.globalFunctions.removeInvisibleChars(value);
  }
}
