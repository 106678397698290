<mat-toolbar *ngIf="!isRouterLogin() && !isRouterSelfRegister() && !isRouterPrivacyPolicy() && hasUser"
  class="header-content d-flex justify-content-betwenn ">
  <div class="col-3">
    <img src="{{ globalFunctions.isMobile() ? logoMobile : logo }}"
      [ngClass]="globalFunctions.isMobile() ? 'logo-mobile' : 'logo'" />
  </div>
  <div class="col-5"></div>
  <div class="col-4 d-flex justify-content-end" *ngIf="!isRouterSelfRegister()">
    <div class="row">
      <div class="col-5 col-sm-3 d-none d-sm-block">
        <div class="d-flex flex-column justify-content-end" style="margin-right: 1px; padding-right: 15px;">
          <div class="row justify-content-end" style="margin-top: 15px;">
            <label class="paragraph paragraph-1 semibold white">{{ user.name }}</label>
          </div>
          <div class="row justify-content-end" *ngIf="isUserClient">
            <label class="pass-13 semibold white">{{user.associatedClientName}}</label>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-3">
        <mat-icon *ngIf="!imageToPreview" [matMenuTriggerFor]="menuProfile" class="white account-circle" [style]="hasProfile ? 'cursor: pointer' : ''">account_circle</mat-icon>
        <img *ngIf="imageToPreview" [matMenuTriggerFor]="menuProfile" [src]="imageToPreview" alt="Imagem selecionada"
          class="img-responsive img-perfil" [style]="hasProfile ? 'cursor: pointer' : ''" />
      </div>
      <div class="col-6 col-sm-3">
        <div class="circle-icon click" [matMenuTriggerFor]="notifications" (menuOpened)="openNotifications()"
          *ngIf="!globalFunctions.isMobile()">
          <mat-icon class="white click icon-circle"
            [style]="notificationsCount > 0 ? 'margin-left: 3px;' : ''">notifications_none</mat-icon>
          <label class="paragraph paragraph-1 notification-count semibold" *ngIf="notificationsCount > 0">{{
            notificationsCount }}</label>
        </div>
        <div class="circle-icon click" (click)="openNotifications()" *ngIf="globalFunctions.isMobile()">
          <mat-icon class="white click icon-circle"
            [style]="notificationsCount > 0 ? 'margin-left: 3px;' : ''">notifications_none</mat-icon>
          <label class="paragraph paragraph-1 notification-count semibold" *ngIf="notificationsCount > 0">{{
            notificationsCount }}</label>
        </div>
      </div>
      <div class="col-3 col-sm-3 d-none d-sm-block">
        <div class="circle-icon click" (click)="logout()">
          <mat-icon class="white click icon-circle">output</mat-icon>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="category-menu" *ngIf="!isRouterLogin() && !isRouterSelfRegister() && !isRouterPrivacyPolicy() && hasUser">
  <div class="col-12 col-sm-12" style="display: flex; width: 100%">
    <div *ngFor="let router of routes; let i = index" class="d-flex flex-row align-items-center">
      <mat-icon class="icon-bar" *ngIf="i == 0" [matMenuTriggerFor]="menu"
        style="margin-right: 10px; margin-left: 5px">menu</mat-icon>
      <label class="paragraph paragraph-1 category-label" (click)="redirectTo(router.path)" [ngClass]="{
          semibold: i == routes.length - 1,
          'truncate-words':
            router.name == networkTitle && router.name.length > 30
        }" *ngIf="!globalFunctions.isMobile()">{{
        router.name == networkTitle
        ? (router.name | slice : 0 : 30)
        : router.name
        }}</label>
      <mat-icon *ngIf="routes.length - 1 > i && !globalFunctions.isMobile()">keyboard_arrow_right
      </mat-icon>
      <div class="d-flex justify-content-end align-items-center" style="margin-left: 64vw;"
        *ngIf="!isHome && globalFunctions.isMobile() && i == 0">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <label class="paragraph paragraph-1 category-label"
          (click)="redirectTo(routes[routes.length - 2].path)">Voltar</label>
      </div>
    </div>
  </div>
</div>

<!-- MENU LATERAL -->
<mat-menu #menu="matMenu" [overlapTrigger]="false" class="menu-opt" xPosition="before" yPosition="below">
  <button mat-menu-item class="btn-menu-nav subtitle subtitle-1" (click)="goToRouter('/home')">
    <mat-icon class="icon-menu-nav material-icons-outlined">home</mat-icon>
    Home
  </button>
  <button *ngxPermissionsOnly="['CLIENT']" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/dashboard')">
    <mat-icon class="icon-menu-nav material-icons-outlined">dashboard</mat-icon>
    Dashboard
  </button>
  <button *ngxPermissionsOnly="['ADMIN']" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/internal-users')">
    <mat-icon class="icon-menu-nav material-icons-outlined">people_outline</mat-icon>
    Usuários Internos
  </button>
  <button *ngxPermissionsOnly="permissions.READ_CLIENT" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/clients')">
    <mat-icon class="icon-menu-nav material-icons-outlined">business</mat-icon>
    {{isUserClient ? 'Dados Cliente' : 'Clientes'}}
  </button>
  <button *ngxPermissionsOnly="permissions.READ_CLIENT_USER" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/users-clients')">
    <mat-icon class="icon-menu-nav material-icons-outlined">supervised_user_circle</mat-icon>
    {{isUserClient ? 'Usuários' : 'Usuários Cliente'}}
  </button>
  <button *ngxPermissionsOnly="permissions.READ_CLIENT_USER_PROFILE" mat-menu-item
    class="btn-menu-nav subtitle subtitle-1" (click)="redirectTo('/user-profiles')">
    <mat-icon class="icon-menu-nav material-icons-outlined">account_circle</mat-icon>
    {{isUserClient ? 'Perfis de Usuários':'Perfis de Usuários Cliente'}}
  </button>
  <button *ngxPermissionsOnly="permissions.READ_USER_CLIENT_GROUP" mat-menu-item
    class="btn-menu-nav subtitle subtitle-1" (click)="redirectTo('/user-client-group')">
    <mat-icon class="icon-menu-nav material-icons-outlined">account_circle</mat-icon>
    Grupos de Usuário
  </button>
  <ng-container *ngxPermissionsOnly="['CLIENT']">
    <button *ngxPermissionsOnly="permissions.READ_COLLABORATOR" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
      (click)="redirectTo('/collaborators')">
      <mat-icon class="icon-menu-nav material-icons-outlined">groups</mat-icon>
      Colaboradores
    </button>
  </ng-container>
  <ng-container *ngxPermissionsOnly="permissions.READ_COLLABORATOR_TRASFER">
    <button *ngxPermissionsOnly="permissions.READ_COLLABORATOR_TRASFER" mat-menu-item
      class="btn-menu-nav subtitle subtitle-1" (click)="redirectTo('/collaborators')">
      <mat-icon class="icon-menu-nav material-icons-outlined">groups</mat-icon>
      Transferência de Colaboradores
    </button>
  </ng-container>
  <button *ngxPermissionsOnly="permissions.IMPORT_IMPORT" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/data-import')">
    <mat-icon class="icon-menu-nav material-icons-outlined">download</mat-icon>
    Importação de Dados
  </button>
  <ng-container *ngxPermissionsOnly="['CLIENT']">
    <button *ngxPermissionsOnly="permissions.READ_NETWORK" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
      (click)="redirectTo('/networks')">
      <mat-icon class="icon-menu-nav material-icons-outlined">feedback</mat-icon>
      Networks
    </button>
  </ng-container>
  <ng-container *ngxPermissionsOnly="['ADMIN']">
    <button *ngxPermissionsOnly="permissions.READ_NOTICES" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
      (click)="redirectTo('/notices')">
      <mat-icon class="icon-menu-nav material-icons-outlined">campaign</mat-icon>
      Avisos
    </button>
  </ng-container>
  <ng-container *ngxPermissionsOnly="['CLIENT']">
    <button *ngxPermissionsOnly="permissions.READ_CLIENT_NOTICES" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
      (click)="redirectToClientNoticeRoute('/notices-client')">
      <mat-icon class="icon-menu-nav material-icons-outlined">campaign</mat-icon>
      Avisos
    </button>
  </ng-container>
  <button *ngxPermissionsOnly="permissions.READ_PARTNER" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/partners')">
    <mat-icon class="icon-menu-nav material-icons-outlined">handshake</mat-icon>
    Parceiros
  </button>
  <button *ngxPermissionsOnly="permissions.READ_BENEFIT" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="goToRouter('/benefits')">
    <mat-icon class="icon-menu-nav material-icons-outlined">loyalty</mat-icon>
    Benefícios
  </button>
  <button *ngxPermissionsOnly="permissions.READ_POST" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/posts')">
    <mat-icon class="icon-menu-nav material-icons-outlined">feed</mat-icon>
    Posts
  </button>
  <button *ngxPermissionsOnly="permissions.READ_SURVEY" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/surveys')">
    <mat-icon class="icon-menu-nav material-icons-outlined">poll</mat-icon>
    Enquetes
  </button>
  <button *ngxPermissionsOnly="permissions.READ_EVENTS" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/events')">
    <mat-icon class="icon-menu-nav material-icons-outlined">today</mat-icon>
    Eventos
  </button>
  <button *ngxPermissionsOnly="permissions.READ_USER_GROUP" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/user-groups')">
    <mat-icon class="icon-menu-nav material-icons-outlined">group</mat-icon>
    Grupos de Colaboradores
  </button>
  <button
    *ngxPermissionsOnly="[permissions.READ_GAMIFICATION, permissions.CAMPAIGN_GAMIFICATION, permissions.EXTRACT_GAMIFICATION, permissions.REWARD_GAMIFICATION, permissions.REWARD_MANAGEMENT_GAMIFICATION]"
    mat-menu-item class="btn-menu-nav subtitle subtitle-1" (click)="redirectTo('/gamification')">
    <mat-icon class="icon-menu-nav material-icons-outlined">sports_esports</mat-icon>
    Gamificação
  </button>
  <button *ngxPermissionsOnly="permissions.READ_PARTNERINTEGRATION" mat-menu-item
    class="btn-menu-nav subtitle subtitle-1" (click)="redirectTo('/proposal-request')">
    <mat-icon class="icon-menu-nav material-icons-outlined">feed</mat-icon>
    Integração Parceiros
  </button>
  <button *ngxPermissionsOnly="permissions.READ_CATEGORY" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/category')">
    <mat-icon class="icon-menu-nav material-icons-outlined">feed</mat-icon>
    Categorias
  </button>
  <button *ngxPermissionsOnly="permissions.READ_COMMUNITY_FORUM" mat-menu-item class="btn-menu-nav subtitle subtitle-1"
    (click)="redirectTo('/community-forum')">
    <mat-icon class="icon-menu-nav material-icons-outlined">forum</mat-icon>
    Fórum da Comunidade
  </button>
  <button mat-menu-item class="btn-menu-nav subtitle subtitle-1" [matMenuTriggerFor]="menuOp4">
    <mat-icon class="icon-menu-nav material-icons-outlined">account_circle</mat-icon>
    Minha Conta
  </button>
  <button mat-menu-item class="subtitle subtitle-1" (click)="logout()">
    <mat-icon class="icon-menu-nav">output</mat-icon>
    Sair
  </button>
</mat-menu>

<!-- SUBMENU LATERAL - MINHA CONTA -->
<mat-menu #menuOp4="matMenu" [overlapTrigger]="false" class="menu-chi">
  <div class="menu-chi-title d-flex justify-content-end px-3">Minha conta</div>
  <button mat-menu-item class="btn-menu-nav paragraph paragraph-1 " (click)="goToMyAccountRouter()">
    Minha Conta
  </button>
  <button mat-menu-item class="paragraph paragraph-1 " (click)="redirectTo('/change-password')">
    Alterar Senha
  </button>
</mat-menu>

<!-- NOTIFICAÇÕES -->
<mat-menu #notifications="matMenu" class="notifications">
  <app-notifications (notificationsCount)="notificationsCount = $event" #appNotification></app-notifications>
</mat-menu>

<!-- SUBMENU - TROCA DE PERFIL -->
<mat-menu #menuProfile="matMenu" class="profile" [class]="hasProfile ? '' : 'hide'">
  <app-persona-select [tooltipLayout]="true"></app-persona-select>
</mat-menu>

<router-outlet></router-outlet>

<toast-component #toastComponent></toast-component>

<div class="loader-box" *ngIf="loadingService.isLoading()">
  <div class="loader"></div>
</div>
<div class="blocker" *ngIf="loadingService.isLoading()"></div>
