import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { UserClientInterface } from "../../interfaces/user-client.interface";
import { UserClient } from "../../domains/user-client/user-client";
import { Observable, catchError, lastValueFrom, map, of, EMPTY as GO_TO_ON_COMPLETE } from "rxjs";
import { ResumedUserClient } from "../../domains/user-client/resumed-user-client";
import { LocalStorageKeys } from '../../enums/storage-keys';

@Injectable({
  providedIn: 'root'
})
export class UserClientServiceMock implements UserClientInterface {

  urlBase: string;
  toastComponent: any;

  constructor(private http: HttpClient) {
    this.urlBase = environment.bff_web;
  }

  async getAll(): Promise<UserClient[]> {
    try {
      const userClients: Array<UserClient> = await lastValueFrom(this.http.get<Array<UserClient>>(this.urlBase + `/users-clients`));
      if (userClients) {
        return userClients;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async delete(id: number): Promise<boolean> {
    try {
      const success: HttpResponse<any> = await lastValueFrom(this.http.delete(this.urlBase + `/users-clients/${id}`, { observe: 'response' }));
      if (success) {
        return true;
      }

    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw error;
    }
    return false;
  }

  async save(userClient: UserClient): Promise<boolean> {
    try {
      const userClients = await lastValueFrom(this.http.post(this.urlBase + `/users-clients`, userClient, { 'headers': { 'Content-Type': 'application/json' } }));
      if (userClients) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
    return false;
  }

  async update(userClient: UserClient): Promise<boolean> {
    try {
      const result = await lastValueFrom(this.http.put(this.urlBase + `/users-clients`, userClient, { 'headers': { 'Content-Type': 'application/json' } }));

      if (result) {
        return true;
      }
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.log(error);
      throw new Error();
    }
    return false;
  }

  async getByClients(): Promise<Array<UserClient>> {
    try {
      const beneficiaries: Array<UserClient> = await lastValueFrom(this.http.get<Array<UserClient>>(this.urlBase + `/users-clients`));

      if (beneficiaries) {
        return beneficiaries;
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error();
  }

  async findById(id: number): Promise<UserClient> {
    try {
      const userClients = await lastValueFrom(this.http.get<UserClient>(this.urlBase + `/users-clients/${id}`));
      return userClients;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async existsByCpf(cpf: string): Promise<Boolean> {
    try {
      const exists = await lastValueFrom(this.http.get<UserClient[]>(this.urlBase + `/users-clients?cpf=${cpf}`));
      if (exists.length) return true;
      return false;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async existsByEmail(email: string): Promise<Boolean> {
    try {
      const exists = await lastValueFrom(this.http.get<UserClient[]>(this.urlBase + `/users-clients?email=${email}`));
      if (exists.length) return true;
      return false;
    } catch (error) {
      this.toastComponent.showApiError(error);
      console.error(error);
      throw new Error();
    }
  }

  async getResumedUserClient(id: number): Promise<ResumedUserClient> {
    try {
      const userClient = await lastValueFrom(this.http.get<ResumedUserClient>(this.urlBase + `/users-clients/user/${id}`));
      return userClient;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async getResumedUserClientByUserIdAndClientId(id: number): Promise<ResumedUserClient> {
    const cachedClientId = localStorage.getItem(LocalStorageKeys.WRH_CACHE_CLIENT_ID);
    try {
      const userClient = await lastValueFrom(this.http.get<ResumedUserClient>(this.urlBase + `/users-clients/user-client/${id}/${cachedClientId}`));
      return userClient;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }
}
